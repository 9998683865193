import React from 'react'
import classes from './Product.module.css'
import Navigation from '../Nav/Navigation'


function Product() {
  return (
    <div >
      <Navigation/>
      <div className={classes.body}>
        <div className={classes.main}>
            
        </div>
        
      </div>
        
    </div>
  )
}

export default Product