import React from "react";
import classes from './Failed.module.css'
import image from '../../../Images/Failed-icon.png'

function Success() {
    return (
        <div>
            <div className={classes.notificationbody}>
                <div className={classes.successcard}>
                    
                    <div className={classes.paygate}>
                        <img src={image} className={classes.interswitch} alt="interswitch" />
                    </div>
                    <div className={classes.mytext}>
                        <p className={classes.firstp}>Failed</p>
                        <p className={classes.secp}>Your payment failed for this package you might want to try again.</p>
                    </div>
                    <button className={classes.lognbtn}>Try again</button>
                </div>

            </div>
        </div>
    )
}

export default Success 