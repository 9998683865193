import React from 'react'
import classes from './AboutUs.module.css'
import Navigation from '../Nav/Navigation'



function AboutUs() {
  return (
    <div >
      <Navigation/>
      <div className={classes.body}>
        <div className={classes.main}>
          
        </div>
      </div>
        
    </div>
  )
}

export default AboutUs