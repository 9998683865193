import React from "react";
import classes from './Success.module.css'
import image from '../../../Images/success-icon.png'
import { NavLink } from 'react-router-dom';

function Success() {
    const navigateExternalLoginLink =()=>{
        window.open('https://recon-app.promixaccounting.com/login', '_blank');
      }
    return (
        <div>
            <div className={classes.notificationbody}>
                <div className={classes.successcard}>
                    
                    <div className={classes.paygate}>
                        <img src={image} className={classes.interswitch} alt="interswitch" />
                    </div>
                    <div className={classes.mytext}>
                        <p className={classes.firstp}>Success</p>
                        <p className={classes.secp}>Your payment is successful for this package login successfully to start reconciling your account</p>
                    </div>
                    <button className={classes.lognbtn}onClick={navigateExternalLoginLink}>Login</button>
                </div>

            </div>
        </div>
    )
}

export default Success 