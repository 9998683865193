import React from "react";
import { NavLink } from 'react-router-dom';
import classes from './Choose.module.css'
import image from '../../../Images/interswitch.png'
import paystack from '../../../Images/paystack.png'
import remita from '../../../Images/remita.png'
import flutterwave from '../../../Images/flutterwave.png'
import paga from '../../../Images/paga.png'

function Choose() {
    return (
        <div className={classes.entirety}>
            <div className={classes.notificationbody}>
                <div className={classes.successcard}>
                    <p className={classes.pgatep}>Choose the Desired payment gateway method </p>
                    <div className={classes.paygate}>
                        <div className={classes.pgate1}>
                        <NavLink to={'/success'} ><img src={image} className={classes.interswitch} alt="interswitch" /></NavLink>
                        <NavLink to={'/success'} ><img src={remita} className={classes.remita} alt="interswitch" /></NavLink>
                        </div>
                        <div className={classes.pgate2}>
                        <NavLink to={'/success'} ><img src={paystack} className={classes.paystack} alt="paystack" /></NavLink>
                        <NavLink to={'/success'} ><img src={flutterwave} className={classes.flutterwave} alt="paystack" /></NavLink>
                        </div>
                    </div>
                    <div>
                    <NavLink to={'/success'} ><img src={paga} className={classes.paga} alt="interswitch" /></NavLink>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default Choose 