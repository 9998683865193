// import './App.css';
import { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from 'react-router-dom';
import Landingpage from './Pages/Landingpage/Landingpage';
import AboutUs from './Pages/About/AboutUs';
import Products from './Pages/Product/Products'
import Navigation from './Pages/Nav/Navigation';
import Home from './Pages/Home/Home';
import Pricings from './Pages/Pricing/Pricing';
import Signup from './Pages/SignUp/SignUp';
import Login from './Pages/Login/Login';
import Success from './Pages/Notifications/Success/Success';
import Failed from './Pages/Notifications/Failed/Failed';
import Choose from './Pages/Notifications/Choose/Choose';
import { useLocation } from 'react-router-dom';
import SignupStarter from './Pages/SignupVarieties/SignupStarter';
import SignupPersonal from './Pages/SignupVarieties/SignupPersonal';
import SignupBusiness from './Pages/SignupVarieties/SignupBusiness';




function App() {
  const location = useLocation();

  useEffect(() => {
    // Scroll to the top of the page when the route changes
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Routes>
        <Route path='/'element={<Landingpage/>}/>
        <Route path='/landing_page'element={<Landingpage/>}/>
        <Route path='/about-us'element={<AboutUs/>}/>
        <Route path='/products'element={<Products/>}/>
        <Route path='/signup'element={<Signup/>}/>
        <Route path='/signup_starter'element={<SignupStarter/>}/>
        <Route path='/signup_personal'element={<SignupPersonal/>}/>
        <Route path='/signup_business'element={<SignupBusiness/>}/>
        <Route path='/login'element={<Login/>}/>
        <Route path='/pricings'element={<Pricings/>}/>
        <Route path='/success'element={<Success/>}/>
        <Route path='/failed'element={<Failed/>}/>
        <Route path='/choose'element={<Choose/>}/>
      </Routes>
    </>
  );
}
export default App;