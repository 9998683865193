import React, { useState, useEffect } from "react";
import classes from './SignUpStarter.module.css'
import Footer from '../../Footer/Footer';
import Navigation from '../Nav/Navigation';
import { NavLink, useNavigate } from 'react-router-dom';
import Check from '../../Images/Check-icon.png'
import axios from 'axios';
import Swal from 'sweetalert2';


function SignupStarter() {
    const [selectedOption, setSelectedOption] = useState('3');
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [reference, setReference] = useState('3');
    const [packagedId, setPackageId] = useState('');
    const [selectedPackageId, setSelectedPackageId] = useState('');
    const [selectedPackageValue, setSelectedPackageValue] = useState('');
    const [password, setPassword] = useState('');
    const [address, setAddress] = useState('');
    const [amount, setAmount] = useState('');
    const [phone, setPhone] = useState('');
    const [companyEmail, setCompanyEmail] = useState('');
    const [companyPhone, setCompanyPhone] = useState('');
    const [companyName, setCompanyName] = useState('');
    const navigate = useNavigate();

    const HandleRegister = async () => {
        setLoading(true);
      
        if (
          !firstName ||
          !lastName ||
          !email ||
          !phone ||
          !address ||
          !companyName ||
          !companyEmail ||
          !companyPhone ||
          !password ||
          !selectedOption
        ) {
          Swal.fire({
            icon: 'error',
            title: 'Failed',
            text: 'Please fill in all fields',
            confirmButtonText: 'Go back',
            confirmButtonColor: 'red',
          });
      
          setLoading(false);
          return;
        }
            
        try {
          const response = await axios.post(
            'https://recon-app.promixaccounting.com/api/customer-register',
            {
                first_name: firstName,
          package_id: selectedPackageId,
          reference: reference,
          password: password,
          address: address,
          amount: amount,
          packageValue: selectedPackageValue,
          last_name: lastName,
          phone_number: phone,
          company_name: companyName,
          email: email,
          company_phone: companyPhone,
          company_email: companyEmail,
            }
          );
      
          console.log('Success:', response.data.message);
      setFirstName('');
      setLastName('');
      setPassword('');
      setAddress('');
      setPhone('');
      setCompanyEmail('');
      setCompanyName('');
      setEmail('');
      setCompanyPhone('');
    //   SETC('');


          
          navigate('/success');
        } catch (error) {
          // Handle error response
          const errorMessage =
            JSON.stringify(error.response.data.error) || 'An error occurred';
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: getErrorMessage(error),
                confirmButtonText: 'Go back',
                confirmButtonColor: 'red',
              });
              
              // Function to extract the error message from the error object
              function getErrorMessage(error) {
                // Check if the error object has a response and data property
                if (error.response && error.response.data) {
                  // Check if there's an 'error' property in the data
                  if (error.response.data.error) {
                    // If 'error' is a string, return it; otherwise, stringify the object
                    return typeof error.response.data.error === 'string'
                      ? error.response.data.error
                      : JSON.stringify(error.response.data.error);
                  }
                }
              
                // If no specific error message found, return a generic message
                return 'An error occurred';
              }
              
      
          console.log('Error:', error.response?.data?.error);
        } finally {
          setLoading(false);
        }
      };


    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    useEffect(() => {
        if (selectedOption === '1') {
          setSelectedPackageId('1');
          setSelectedPackageValue('Business');
          setAmount(10000);
        } else if (selectedOption === '2') {
          setSelectedPackageId('2');
          setSelectedPackageValue('Personal');
          setAmount(5000);
        } else if (selectedOption === '3') {
          setSelectedPackageId('3');
          setSelectedPackageValue('Standard');
          setAmount(0);
        }
      }, [selectedOption]);
    
      const getDescription = () => {

        if (selectedOption === '1') {
            return (
                <div className={classes.pricingshi}>
                    <h1 className={classes.myh1}>₦10,000/mth</h1>
                    <p className={classes.myp}>Business plan</p>
                    <p className={classes.my2p}>Use For Commercial.</p>
                    <div className={classes.myhp}>
                        <p className={classes.mysubp}>
                            <img src={Check} className={classes.Check} alt="Check-icon" />
                            Unlimited accounts
                        </p>
                        <p className={classes.mysubp}>
                            <img src={Check} className={classes.Check} alt="Check-icon" />
                            All Auto Match Features
                        </p>
                        <p className={classes.mysubp}><img src={Check} className={classes.Check} alt="Check-icon" />Manual Matching Features</p>
                        <p className={classes.mysubp}><img src={Check} className={classes.Check} alt="Check-icon" />Reconciliation Report in Excel and PDF</p>
                        <p className={classes.mysubp}><img src={Check} className={classes.Check} alt="Check-icon" />Outstanding Items Report</p>
                    </div>
                </div>
            );
        } else if (selectedOption === '2') {
            return (
                <div className={classes.pricingshi}>
                    <h1 className={classes.myh1}>₦5,000/mth</h1>
                    <p className={classes.myp}>Standard plan</p>
                    <p className={classes.my2p}>Use For Personal.</p>
                    <div className={classes.myhp}>
                        <p className={classes.mysubp}>
                            <img src={Check} className={classes.Check} alt="Check-icon" />
                            10 accounts
                        </p>
                        <p className={classes.mysubp}>
                            <img src={Check} className={classes.Check} alt="Check-icon" />
                            All Auto Match Features
                        </p>
                        <p className={classes.mysubp}><img src={Check} className={classes.Check} alt="Check-icon" />Manual Matching</p>
                        <p className={classes.mysubp}><img src={Check} className={classes.Check} alt="Check-icon" />Reconciliation </p>
                        <p className={classes.mysubp}><img src={Check} className={classes.Check} alt="Check-icon" />Outstanding Items Report</p>
                    </div>
                </div>
            );
        } else if (selectedOption === '3') {
            return (
                <div className={classes.pricingshi}>
                    <h1 className={classes.myh1}>₦0/mth</h1>
                    <p className={classes.myp}>Starter plan</p>
                    <p className={classes.my2p}>Use Only For Personal.</p>
                    <div className={classes.myhp}>
                        <p className={classes.mysubp}>
                            <img src={Check} className={classes.Check} alt="Check-icon" />
                            1 Accounts
                        </p>
                        <p className={classes.mysubp}>
                            <img src={Check} className={classes.Check} alt="Check-icon" />
                            All Auto Match Features
                        </p>
                        <p className={classes.mysubp}><img src={Check} className={classes.Check} alt="Check-icon" />Manual Matching Features</p>
                        <p className={classes.mysubp}><img src={Check} className={classes.Check} alt="Check-icon" />Reconciliation Report in Excel and PDF</p>
                        <p className={classes.mysubp}><img src={Check} className={classes.Check} alt="Check-icon" />Outstanding Items Report</p>
                    </div>
                </div>
            );
        }
        return null;
    };

    const inlineStyles = {

        // marginTop: '24px',
    };

    return (
        <div>
            <Navigation />
            <div className={classes.signupent}>
                <div className={classes.signupHero}>
                    <div className={classes.content}>
                        <p className={classes.herotext}>Sign up</p>
                        <p className={classes.pherotext}>We believe Promix should be accessible<br></br>to all companies, no matter the size..</p>
                    </div>

                </div>
                <div className={classes.signupbody}>
                    <div className={classes.theinputs}>
                        <div className={classes.inputfield}>
                            <label htmlFor="myInput" className={classes.label}>First Name</label> <br></br>
                            <input type="text" className={classes.myinput} placeholder="Enter your first name" value={firstName}  onChange={(e) => setFirstName(e.target.value)} />
                        </div>
                        <div className={classes.inputfield}>
                            <label htmlFor="myInput" className={classes.label}>Last Name</label> <br></br>
                            <input type="text" className={classes.myinput} placeholder="Enter your last name" value={lastName}  onChange={(e) => setLastName(e.target.value)} />
                        </div>
                        <div className={classes.inputfield}>
                            <label htmlFor="myInput" className={classes.label}>Email</label> <br></br>
                            <input type="email" className={classes.myinput} placeholder="Enter your email" value={email}  onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className={classes.inputfield}>
                            <label htmlFor="myInput" className={classes.label}>Phone Number</label> <br></br>
                            <input type="text" className={classes.myinput} placeholder="Enter Phone Number" value={phone}  onChange={(e) => setPhone(e.target.value)} />
                        </div>
                        <div className={classes.inputfield}>
                            <label htmlFor="myInput" className={classes.label}>Address</label> <br></br>
                            <input type="text" className={classes.myinput} placeholder="Enter address" value={address}  onChange={(e) => setAddress(e.target.value)}/>
                        </div>
                        

                    </div>
                    <div className={classes.theinputs} style={inlineStyles} >
                        <div className={classes.inputfield}>
                        

                        <div className={classes.inputfield}>
                            <label htmlFor="myInput" className={classes.label}>Company Name</label> <br></br>
                            <input type="text" className={classes.myinput} placeholder="Enter company name" value={companyName}  onChange={(e) => setCompanyName(e.target.value)}/>
                        </div>

                            <div className={classes.inputfield}>
                                <label htmlFor="myInput" className={classes.label}>Company Email</label> <br></br>
                                <input type="email" className={classes.myinput} placeholder="Enter company email" value={companyEmail}  onChange={(e) => setCompanyEmail(e.target.value)} />
                            </div>

                            <div className={classes.inputfield}>
                                <label htmlFor="myInput" className={classes.label}>Company Phone</label> <br></br>
                                <input type="text" className={classes.myinput} placeholder="Enter company phone" value={companyPhone}  onChange={(e) => setCompanyPhone(e.target.value)} />
                            </div>
                           
                            <div className={classes.inputfield}>
                            <label htmlFor="myInput" className={classes.label}>Password</label> <br></br>
                            <input type="text" className={classes.myinput} placeholder="Create your password" value={password}  onChange={(e) => setPassword(e.target.value)}/>

                        </div>
                            
                            <div className={classes.inputfield}>
                                <label htmlFor="myInput" className={classes.label}>Confirm password</label> <br></br>
                                <input type="text" className={classes.myinput} placeholder="Confirm your created password" />

                            </div>
                        </div>

                    </div>



                    <div className={classes.pricingshi}>
                        {getDescription()}
                        <label htmlFor="myInput" className={classes.label}>Select Package</label> <br />
                        <select className={classes.myinput} value={selectedOption} onChange={handleSelectChange}>
                            {/* <option value="" disabled>Select a package</option> */}
                            <option value="1">Business</option>
                            <option value="2">Standard</option>
                            <option value="3">Starter</option>
                        </select>


                    </div>
                </div>
                <button onClick={HandleRegister} type="button" className={classes.prcdbtn}>{loading ? "Proceeding to payment page......" : "Proceed to payment"}</button>

            </div>
            <Footer />
        </div>

    )
}

export default SignupStarter; 