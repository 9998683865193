import React from 'react';
import classes from './Login.module.css';
import { Link, useNavigate } from 'react-router-dom';
import Navigation from '../Nav/Navigation';
import { Navigate } from 'react-router-dom';
import Signup from '../SignUp/SignUp';

function Login() {
 
    const navigate = useNavigate();

     const SignUp = () =>{
        navigate('/SignUp')
     }

  return (
    <div>
        <Navigation/>
        <div className={classes.body}>
            <div className={classes.main}>
                <div className={classes.Login}>
                    <div className={classes.LoginHeader}>
                        <h2>Log in</h2>
                        <p>Welcome back! Please enter your details.</p>
                    </div>
                    <form>
                        <div>
                            <label>Email</label><br/>
                            <input type='email' className={classes.formInput} placeholder='Enter your email'></input>
                        </div>
                        <div>
                            <label>Password</label><br/>
                            <input type='password' className={classes.formInput}  placeholder='Enter your email'></input>
                        </div>
                        <div className={classes.section}>
                            <span>
                                <input type='radio'></input>
                                <p>Remember for 30 days</p>
                            </span>
                            <Link to={'#'} className={classes.link}>Forgot password</Link>
                        </div>
                        <button className={classes.SignInBtn}>Sign in</button>
                    </form>
                    <span className={classes.dntHvAcct}>
                        <p>Don’t have an account?</p>
                        <span onClick={SignUp}>Sign up</span>
                    </span>
                </div>
            </div>
            
        </div>

    </div>
  )
}

export default Login